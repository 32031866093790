<template>
  <div class="static-content-wrapper">
    <h1>About Us</h1>
    <section>
      <div>
        <p>
          At Agility Chimp we understand the need to keep records of your
          agility trials. For us, it all started when we signed up for our first
          agility class. With no expectations on what this would be about, or if
          we would even enjoy it, we showed up with an open mind and an
          eagerness to learn.
        </p>

        <p>
          Seeing the joy on my dog Sebastian's face during and after that class
          I knew we would be back for more! Over the next few weeks we practiced
          together, learned about the equipment and how to maneuver across,
          through, and over everything. The bond and trust that was created with
          Sebastian during this process is something that lives strong in our
          family to this day. And is something that I am grateful to have with
          him.
        </p>
      </div>
      <img :src="AgilityTraining" alt="Aglity Training" />
    </section>
    <section>
      <img :src="WhiteSands" alt="White Sands" />

      <p>
        This bond allows us to take him pretty much everywhere we go, including
        restaurants, shopping (when dogs allowed inside), and even on vacations.
        You can definitely say he is an integral part of the family and we would
        not be as strong without him in our pack.
      </p>
    </section>
    <p>
      Being web developers, my wife and I created Agility Chimp so that you
      could have a digital record of each trial and each class you and your dog
      attend. If you have ever lost a physical record book, spilled water on it,
      or your dog used it as a chew toy then you understand the need for digital
      records.
    </p>
  </div>
</template>

<script>
import AgilityTraining from "../assets/Agility-Training.png";
import WhiteSands from "../assets/White-Sands.png";

export default {
  data() {
    return {
      AgilityTraining,
      WhiteSands,
    };
  },
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;

  img {
    height: 175px;
    width: auto;
    margin: 10px;
  }
}
</style>
